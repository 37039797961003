import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "C:/xampp/htdocs/hausman/app/src/client/template.js";
import SymbolSvg from "../assets/images/site-branding/symbol.inline.svg";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const AnimateIntroBanner = makeShortcode("AnimateIntroBanner");
const RowLines = makeShortcode("RowLines");
const Block = makeShortcode("Block");
const Heading = makeShortcode("Heading");
const LearnMore = makeShortcode("LearnMore");
const ParagraphSlider = makeShortcode("ParagraphSlider");
const Paragraph = makeShortcode("Paragraph");
const Row = makeShortcode("Row");
const AnimateIntroBlock = makeShortcode("AnimateIntroBlock");
const Decoration = makeShortcode("Decoration");
const AnimateIntroRow = makeShortcode("AnimateIntroRow");
const AnchorCTA = makeShortcode("AnchorCTA");
const Anchor = makeShortcode("Anchor");
const ScrollPositionIntro = makeShortcode("ScrollPositionIntro");
const AnimateIntroListBlockItem = makeShortcode("AnimateIntroListBlockItem");
const BlockNews = makeShortcode("BlockNews");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <AnimateIntroBanner id="banner" className="background_logo dark" background={<><RowLines mdxType="RowLines" /><SymbolSvg mdxType="SymbolSvg" /></>} mdxType="AnimateIntroBanner">
  <Block mdxType="Block">
    <Heading mdxType="Heading">
      Hausman's
      <br /> Story
    </Heading>
    <LearnMore elementId="learn_more_target" color="turq" mdxType="LearnMore" />
  </Block>
  <Block className="content_block" mdxType="Block">
    <ParagraphSlider mdxType="ParagraphSlider">
      <Paragraph mdxType="Paragraph">The name Hausman is inspired by the famous Baron Georges-Eugène Haussmann, Paris’ visionary who transformed the city in the 19th century.</Paragraph>
      <Paragraph mdxType="Paragraph">We provide Integrated Operations & Maintenance Solutions to property and asset owners, by managing, operating, and maintaining their complex facilities, properties, and industrial camp accommodations in Lebanon and the Middle East region.</Paragraph>
    </ParagraphSlider>
  </Block>  
    </AnimateIntroBanner>
    <Row id="learn_more_target" className="content" background={<RowLines mdxType="RowLines" />} mdxType="Row">
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Vision</Heading>
  <Heading level={4} mdxType="Heading">Commitment</Heading>
  <div>
  <Paragraph mdxType="Paragraph">To become pioneers in functionality and efficiency, by creating a long-lasting impact on cities and communities.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/about/vision.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>
  
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Mission</Heading>
  <div>
  <Paragraph mdxType="Paragraph">To bring our clients complete peace of mind through a “zero-downtime” strategy, with a focus on optimal efficiency, continuous functionality, safety and security.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/about/mission.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>
  
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Environment, Health & Safety</Heading>
  <div>
  <Paragraph mdxType="Paragraph">To execute procedures in compliance with the strictest rules, regulations, and internationally recognized standards for environmental protection and the health and safety of all concerned entities.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/about/health-safety-environment.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="content_block" mdxType="AnimateIntroBlock">
  <Heading mdxType="Heading">Corporate Social Responsibility</Heading>
  <div>
  <Paragraph mdxType="Paragraph">To give back to communities and create a positive impact on social, economic, and environmental factors through sustainable development and ethical standards.</Paragraph>
  </div>
  </AnimateIntroBlock>
  <AnimateIntroBlock className="decoration_block" mdxType="AnimateIntroBlock">
    <div className="decoration_wrapper small"><Decoration from="/about/csr.jpg" mdxType="Decoration" /></div>
  </AnimateIntroBlock>
    </Row>
    <AnimateIntroRow className="featured" background={<><RowLines mdxType="RowLines" /><Decoration from="/about/knowledge-bg.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading level={4} mdxType="Heading">Knowledge</Heading>
  <Heading level={2} mdxType="Heading">Where Expertise Meets Technology</Heading>
  <AnchorCTA to="/services" mdxType="AnchorCTA">See services</AnchorCTA>
  </Block>
  <Block className="content_block" mdxType="Block">
  <Paragraph mdxType="Paragraph">Hausman's team of experts and engineers combine their industrial know-how and technical expertise with cutting-edge information technology to deliver services of the highest quality, in partnership with leading consultants from the Middle East region and the United Kingdom.</Paragraph>
  </Block>
    </AnimateIntroRow>
    <AnimateIntroRow id="awards" background={<RowLines mdxType="RowLines" />} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading level={4} mdxType="Heading">Awards & Recognition</Heading>
  <div className="award">
  <Heading level={3} className="intro_from_left" mdxType="Heading">IWFM</Heading>
  <Paragraph className="numbered parallax_wrapper" mdxType="Paragraph"><span className="parallax"><span className="number">1</span></span> Membership certificate (Institute of Workplace and Facilities Management)</Paragraph>
  </div>
  <div className="award">
  <Heading level={3} className="intro_from_left" mdxType="Heading">MEFMA</Heading>
  <Paragraph className="numbered parallax_wrapper" mdxType="Paragraph"><span className="parallax"><span className="number">2</span></span> Corporate membership certificate (Member of the Middle East Facility Management Association)</Paragraph>
  </div>
  </Block>
    </AnimateIntroRow>
    <AnimateIntroRow id="network" className="featured" background={<><RowLines mdxType="RowLines" /><Decoration from="/about/network-bg.jpg" mdxType="Decoration" /></>} mdxType="AnimateIntroRow">
  <Block className="content_block" mdxType="Block">
  <Heading level={4} mdxType="Heading">Network</Heading>
  <Heading level={2} mdxType="Heading">HAUSMAN is part of the prominent MAN Holding
  </Heading>
  <AnchorCTA to="/projects" mdxType="AnchorCTA">See Projects</AnchorCTA>
  </Block>
  <Block className="content_block" mdxType="Block">
  <Paragraph mdxType="Paragraph">From an independent office established in Lebanon in 1971, MAN Holding steadily grew to become a leading construction and engineering network with a global reach.</Paragraph>
    
  <ul className="partner_logos stagger_list">
    <li>
      <Anchor to="http://www.manenterprise.com" mdxType="Anchor">
        <Decoration from="/partner-companies/man-enterprise.svg" alt="Man Enterprise" mdxType="Decoration" />
      </Anchor>
    </li>
    <li>
      <Anchor to="http://www.tecmanindustry.com" mdxType="Anchor">
        <Decoration from="/partner-companies/tecman.svg" alt="Tecman" mdxType="Decoration" />
      </Anchor>
    </li>
    <li>
      <Anchor to="http://www.metrixmep.com" mdxType="Anchor">
        <Decoration from="/partner-companies/metrix.svg" alt="Metrix" mdxType="Decoration" />
      </Anchor>
    </li>
  </ul>
  </Block>
    </AnimateIntroRow>
    <Row id="principles" background={<RowLines disableScrollIndicator={true} mdxType="RowLines" />} mdxType="Row">
  <Block className="content_block" mdxType="Block">
  <div className="stick_to_container">
  <Heading level={2} mdxType="Heading">Principles</Heading>
  <Paragraph mdxType="Paragraph">At Hausman, we are driven by a firm resolution to carry on the Group's legacy by staying true to its established quality standards and core values, known as the 6 C's.</Paragraph>  
  </div>
  </Block>
  <Block className="list_block" mdxType="Block">
  <ScrollPositionIntro className="scroll_line" mdxType="ScrollPositionIntro" />
  <AnimateIntroListBlockItem className="item" mdxType="AnimateIntroListBlockItem">
  <span className="number">01</span>
  <Heading level={3} mdxType="Heading">Commitment</Heading>
  <Paragraph mdxType="Paragraph">Our word is at the base of every endeavor we embark on.</Paragraph>
  </AnimateIntroListBlockItem>
  <AnimateIntroListBlockItem className="item" mdxType="AnimateIntroListBlockItem">
  <span className="number">02</span>
  <Heading level={3} mdxType="Heading">Community</Heading>
  <Paragraph mdxType="Paragraph">Act as supporters for where we work and live in. We champion safety, ethics, respect for people and culture and transfer of knowledge.</Paragraph>
  </AnimateIntroListBlockItem>
  <AnimateIntroListBlockItem className="item" mdxType="AnimateIntroListBlockItem">
  <span className="number">03</span>
  <Heading level={3} mdxType="Heading">Competence</Heading>
  <Paragraph mdxType="Paragraph">Sustain excellence with positive, actionable growth, through valuing and developing each and everyone’s skills.</Paragraph>
  </AnimateIntroListBlockItem>
  <AnimateIntroListBlockItem className="item" mdxType="AnimateIntroListBlockItem">
  <span className="number">04</span>
  <Heading level={3} mdxType="Heading">Collaboration</Heading>
  <Paragraph mdxType="Paragraph">Teamwork and partnerships are founded on trust transparency, and truth.</Paragraph>
  </AnimateIntroListBlockItem>
  <AnimateIntroListBlockItem className="item" mdxType="AnimateIntroListBlockItem">
  <span className="number">05</span>
  <Heading level={3} mdxType="Heading">Creativity</Heading>
  <Paragraph mdxType="Paragraph">Approach our work with openness, curiosity, imagination, and a sense of adventure for the new.</Paragraph>
  </AnimateIntroListBlockItem>
  <AnimateIntroListBlockItem className="item" mdxType="AnimateIntroListBlockItem">
  <span className="number">06</span>
  <Heading level={3} mdxType="Heading">Consistency</Heading>
  <Paragraph mdxType="Paragraph">We persevere with great guidance and optimal targets to turn the challenge into success.</Paragraph>
  </AnimateIntroListBlockItem>        
  </Block>
    </Row>
    <Row id="news" background={true} data-background-animation="false" mdxType="Row">
  <BlockNews id="news_block" className="content_block" mdxType="BlockNews" />
    </Row>
    <Row className="whitespace" background={<RowLines mdxType="RowLines" />} mdxType="Row" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      